import React, { useEffect, useRef } from "react";
import { CustomButton, Header, SmallScreenHeader } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../contexts";
import "./addresses.css";
import { useState } from "react";
import {
  APIURL,
  APPDATAURLS,
  GOOGLEMAPSAPIKEY,
  MAPBOXACCESSTOKEN,
} from "../../../utils/APPADATAURLS";
import Select from "react-select";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import { FaMapPin } from "react-icons/fa";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Modal } from "react-bootstrap";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { usePost, useUserLocation } from "../../../hooks";

// mapbox
import mapboxgl from "mapbox-gl";

const icon = L.icon({
  iconUrl: iconMarker,
});

const mapKey = process.env.GOOGLEMAPSAPIKEY;
const AddAddress = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useContext(UserContext);
  const [credentials, setCredentials] = useState({
    address: "",
    city: "",
    pinCode: "",
    state: "",
    latitude: "",
    longitude: "",
    mapAreaName: "",
  });

  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLEMAPSAPIKEY,
  });

  const [directionsService, setDirectionsService] = useState(null);
  const [directionsDisplay, setDirectionsDisplay] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  let [isDisplayed, setIsDisplayed] = useState(false);
  useEffect(() => {
    setDirectionsDisplay(null);
    if (map) {
      setDirectionsService(new window.google.maps.DirectionsService());
      setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
    }

    if (directionsDisplay) {
      setIsDisplayed(true);
      directionsDisplay.setMap(map);
      directionsDisplay.setOptions({ suppressMarkers: true });
      //showDirection(delivery.results.products[0].latitude,delivery.results.products[0].longitude);
      //setIsUpdated(!isUpdated);
    }
  }, [map, isUpdated]);

  const [coordinates, setCoordinates] = useState([0, 0]);

  const [activeTab, setActiveTab] = useState(1);

  const [locationIndex, setLocationIndex] = useState({
    countryIndex: 0,
    countyIndex: 0,
    cityIndex: 0,
    locationIndex: 0,
  });

  const [availableLocations, setAvailableLocations] = useState({
    countries: [],
    counties: [],
    cities: [],
    locations: [],
  });

  const [userSelection, setUserSelection] = useState({
    countryID: null,
    countyID: null,
    cityID: null,
    locationID: null,
    description: null,
    latitude: null,
    longitude: null,
    mapAreaName: null,
  });

  const [data, setData] = useState({
    loading: false,
    error: false,
    message: "",
    results: [],
  });
  const fectchLocations = () => {
    setData({
      loading: true,
      error: false,
      message: "",
      results: [],
    });
    fetch(APPDATAURLS.getAvailableLocations, { method: "GET" })
      .then((req) => req.json())
      .then((res) => {
        console.log(res.data[0].counties);
        setData({
          loading: false,
          error: res.error,
          message: res.message,
          results: res.data,
        });
        setAvailableLocations({
          ...availableLocations,
          countries: res.data,
        });
      })
      .catch((e) => {
        setData({
          loading: false,
          error: true,
          message:
            "An error occurred while getting locations: Try again later.",
          results: [],
        });
      });
  };
  const add = () => {
    let formData = new FormData();
    formData.append("add-shipping-address", "add");
    formData.append("shippingAddress", credentials.address);
    formData.append("shippingCity", credentials.city);
    formData.append("shippingPincode", credentials.pinCode);
    formData.append("shippingState", credentials.state);
    formData.append("userID", userData.data.userID);
    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((res) => {
        console.log(res);
        setCredentials({
          address: res.data.shippingAddress,
          city: res.data.shippingCity,
          pinCode: res.data.shippingPincode,
          state: res.data.shippingState,
        });
        navigate("/profile/addresses");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [errors, setErrors] = useState({
    state: false,
    message: "",
  });

  const addAddress = usePost({ url: APIURL });
  const [modalOpen, setModalOpen] = useState(false);

  const addUserAddress = async () => {
    let formData = new FormData();
    if (activeTab == 1) {
      if (userSelection.latitude == null) {
        setErrors({
          state: true,
          message: "Grant location access to pin a location.",
        });
        return false;
      }
    } else {
      if (
        !userSelection.countryID &&
        !userSelection.countyID &&
        !userSelection.cityID &&
        !userSelection.locationID
      ) {
        setErrors({
          state: true,
          message: "Please select a location.",
        });
        return false;
      }
    }
    if (userLocation.data && !userLocation.data?.error_message) {
      formData.append("add-user-address", "add");
      formData.append("countryID", userSelection.countryID);
      formData.append("countyID", userSelection.countyID);
      formData.append("cityID", userSelection.cityID);
      formData.append("locationID", userSelection.locationID);
      formData.append("description", userSelection.description);
      formData.append("latitude", userLocation.coords.lat);
      formData.append("longitude", userLocation.coords.lng);
      formData.append("map_data", JSON.stringify(userLocation));
      formData.append("userID", userData.data.id);
      formData.append(
        "title",
        userLocation.data
          ? userLocation.data?.features[0].properties?.full_address
          : ""
      );

      const addAnAddress = await addAddress.handleRequest(null, formData);

      if (!addAnAddress.error && !addAnAddress.loading) {
        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
          window.history.back();
        }, 3000);
      }
    }
  };

  const [userLocation, setUserLocation] = useState({
    coords: {
      lat: null,
      lng: null,
    },
    data: null,
  });

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates([position.coords.latitude, position.coords.longitude]);

          fetch(
            `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}&access_token=${MAPBOXACCESSTOKEN}`
            // `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat
            // =${position.coords.latitude}&lon=${position.coords.longitude}`,
            //`https://maps.google.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${GOOGLEMAPSAPIKEY}`
          )
            .then((req) => req.json())
            .then((res) => {
              if (!userLocation.data) {
                setCredentials({
                  ...credentials,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                });
                setUserSelection({
                  ...userSelection,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                });
                setUserLocation({
                  coords: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                  data: res,
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        },
        (error) => {
          setErrors({
            state: true,
            message: "An error occurred, try again later.",
          });
        },
        {
          enableHighAccuracy: false,
          timeout: 15000,
          maximumAge: 0,
        }
      );
    } else {
      setErrors({
        state: true,
        message: "OOPS!! Location services not supported!",
      });
    }
  };

  useEffect(() => {
    //fectchLocations();
    getUserLocation();
  }, []);

  const [zoom, setZoom] = useState(12);
  useEffect(() => {
    if (userLocation.data) {
      //setZoom(15);
    }
  }, [userLocation]);

  //Mapbox

  const location = useUserLocation();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  useEffect(() => {
    mapboxgl.accessToken = MAPBOXACCESSTOKEN;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [location.coordinates.longitude, location.coordinates.latitude],
      zoom: 14,
    });

    let popup = new mapboxgl.Popup().setText("Me").addTo(mapRef.current);
    new mapboxgl.Marker({
      color: "var(--app-color)",
      label: "Me",
      title: "Me",
    })
      .setLngLat([
        location.coordinates.longitude,
        location.coordinates.latitude,
      ])
      .addTo(mapRef.current)
      .setPopup(popup);
  }, [location]);

  ///////////////

  return (
    <div className="body">
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        onBackdropClick={() => setModalOpen(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {addAddress.message}
          <p>Redirecting...</p>
        </Modal.Body>
      </Modal>

      <Modal
        show={errors.state}
        onHide={() => setErrors({ ...errors, state: false })}
        onBackdropClick={() => setErrors({ ...errors, state: false })}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{errors.message}</Modal.Body>
      </Modal>
      <Header />
      <div className="addresses-wrapper">
        <SmallScreenHeader title={"Add address"} />
        <p className="heading">Add address</p>

        {/* <div
        className='tabs-btns'
        >
          <CustomButton
            pressAction={()=>setActiveTab(0)}
            title={"Select area"}
            backgroundColor={activeTab==0?"var(--app-color)":"#f5f5f5"}
            color={activeTab==0?"#ffffff":"#000000"}
            borderRadius={5}
          />
          <CustomButton
            pressAction={()=>{
              setActiveTab(1);
              getUserLocation();
            }}
            title={"Use my current location"}
            backgroundColor={activeTab==1?"var(--app-color)":"#f5f5f5"}
            color={activeTab==1?"#ffffff":"#000000"}
            borderRadius={5}
          />

        </div> */}
        {activeTab == 0 ? (
          <>
            <Select
              options={availableLocations.countries.map((item, index) => {
                return { value: item.countryName, label: item.countryName };
              })}
              placeholder="Select Country"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  paddingLeft: 15,
                  marginTop: 5,
                  marginBottom: 5,
                }),
              }}
              onChange={(e) => {
                let index = availableLocations.countries
                  .map((item) => item.countryName)
                  .indexOf(e.value);
                setLocationIndex({
                  ...locationIndex,
                  countryIndex: index == -1 ? 0 : index,
                });
                setAvailableLocations({
                  ...availableLocations,
                  counties: [],
                  locations: [],
                  cities: [],
                });
                setAvailableLocations({
                  ...availableLocations,
                  counties: data.results[index == -1 ? 0 : index].counties,
                  locations: [],
                  cities: [],
                });
                setUserSelection({
                  ...userSelection,
                  countryID: data.results[index == -1 ? 0 : index].countryID,
                });
                console.log(
                  userSelection,
                  data.results[index == -1 ? 0 : index].countryID
                );
              }}
            />

            <Select
              options={availableLocations.counties.map((item, index) => {
                return { value: item.countyName, label: item.countyName };
              })}
              placeholder="Select County"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  paddingLeft: 15,
                  marginTop: 5,
                  marginBottom: 5,
                }),
              }}
              onChange={(e) => {
                let index = availableLocations.counties
                  .map((item) => item.countyName)
                  .indexOf(e.value);
                setLocationIndex({
                  ...locationIndex,
                  countyIndex: index == -1 ? 0 : index,
                });
                setAvailableLocations({
                  ...availableLocations,
                  cities:
                    data.results[locationIndex.countryIndex].counties[
                      index == -1 ? 0 : index
                    ].cities,
                  locations: [],
                });
                setUserSelection({
                  ...userSelection,
                  countyID:
                    data.results[locationIndex.countryIndex].counties[
                      index == -1 ? 0 : index
                    ].countyID,
                });
                console.log(
                  userSelection,
                  data.results[locationIndex.countryIndex].counties[
                    index == -1 ? 0 : index
                  ].countyID
                );
              }}
            />

            <Select
              options={availableLocations.cities.map((item, index) => {
                return { value: item.cityName, label: item.cityName };
              })}
              placeholder="Select City"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  paddingLeft: 15,
                  marginTop: 5,
                  marginBottom: 5,
                }),
              }}
              onChange={(e) => {
                let index = availableLocations.cities
                  .map((item) => item.cityName)
                  .indexOf(e.value);
                setLocationIndex({
                  ...locationIndex,
                  cityIndex: index == -1 ? 0 : index,
                });
                setAvailableLocations({
                  ...availableLocations,
                  locations:
                    data.results[locationIndex.countryIndex].counties[
                      locationIndex.countyIndex
                    ].cities[index == -1 ? 0 : index].locations,
                });
                setUserSelection({
                  ...userSelection,
                  cityID:
                    data.results[locationIndex.countryIndex].counties[
                      locationIndex.countyIndex
                    ].cities[index == -1 ? 0 : index].cityID,
                });
                console.log(
                  data.results[locationIndex.countryIndex].counties[
                    locationIndex.countyIndex
                  ].cities[index == -1 ? 0 : index].cityID
                );
              }}
            />

            <Select
              options={availableLocations.locations.map((item, index) => {
                return { value: item.locationName, label: item.locationName };
              })}
              placeholder="Select location"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  paddingLeft: 15,
                  marginTop: 5,
                  marginBottom: 5,
                }),
              }}
              onChange={(e) => {
                let index = availableLocations.locations
                  .map((item) => item.locationName)
                  .indexOf(e.value);
                setLocationIndex({
                  ...locationIndex,
                  locationIndex: index == -1 ? 0 : index,
                });
                setUserSelection({
                  ...userSelection,
                  locationID:
                    data.results[locationIndex.countryIndex].counties[
                      locationIndex.countyIndex
                    ].cities[locationIndex.cityIndex].locations[
                      index == -1 ? 0 : index
                    ].locationID,
                });
              }}
            />
            <textarea
              onChange={(e) =>
                setUserSelection({
                  ...userSelection,
                  description: e.target.value,
                })
              }
              className="address-input"
              style={{ height: 100 }}
              placeholder="(Optional) Write a brief description of your region..."
            />
          </>
        ) : (
          <div className="map">
            <div
              style={{ height: "100%" }}
              ref={mapContainerRef}
              className="map-container"
            ></div>
            {/* {isLoaded && userLocation.coords.lat ? (
              <>
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  zoom={zoom}
                  
                  center={{
                    lat: userLocation.coords.lat,
                    lng: userLocation.coords.lng,
                  }}
                  onLoad={(map) => {
                    const bounds = new window.google.maps.LatLngBounds({
                      lat: userLocation.coords.lat,
                      lng: userLocation.coords.lng,
                    });
                    map.fitBounds(bounds);
                    map.setZoom(zoom);
                    setMap(map);
                    setIsUpdated(!isUpdated);
                  }}
                  onUnmount={(map) => {
                    setMap(null);
                  }}
                >
                  <MarkerF
                    onClick={(e) => console.log(e)}
                    position={{
                      lat: userLocation.coords.lat,
                      lng: userLocation.coords.lng,
                    }}
                    title="Me"
                    label={"Me"}
                  />
                </GoogleMap>
              </>
            ) : (
              <MapContainer
                center={[
                  userLocation.coords.lat ?? 0,
                  userLocation.coords.lng ?? 0,
                ]}
                zoom={13}
                scrollWheelZoom={false}
                style={{ width: "100%", height: "100%", zIndex: 1 }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    userLocation.coords.lat ?? 0,
                    userLocation.coords.lng ?? 0,
                  ]}
                  icon={icon}
                  key={"User"}
                >
                  <Tooltip>My selected location</Tooltip>
                  <Popup>My location</Popup>
                </Marker>
              </MapContainer>
            )} */}
          </div>
        )}
      </div>

      {userLocation.data ? (
        <div className="location">
          <FaMapPin style={{ marginRight: 10 }} />{" "}
          {userLocation.data?.features[0].properties?.full_address}
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          position: "fixed",
          bottom: 10,
          zIndex: 8,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomButton
          pressAction={() => {
            addUserAddress();
          }}
          disabled={addAddress.loading && !userLocation.data}
          title={addAddress.loading ? "Saving..." : "Use the marked region"}
          backgroundColor={"var(--app-color)"}
          borderRadius={5}
          width={"70%"}
        />
      </div>
    </div>
  );
};

export default AddAddress;
