import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth, CartPage, Checkout, FavoritesPage, FlashSale, HomePage, 
  MapPage, 
  OrderDetails, 
  Others, 
  ProductPage, 
  ProductsPage, 
  Rating, 
  SearchScreen, 
  Shop, 
  ShopDashboard, 
  ShopPage, 
  SingleCategoryPage, 
  TrialPages, 
  User} from "./pages";
import { useState,useMemo, useEffect } from 'react';
import { CartContext, FavoritesContext, ProductSelectedContext, UserContext } from './contexts';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookieBanner, Footer } from './components';
import MapBoxMap from './pages/MapPage/MapBoxMap';
import { useNetworkConnection } from './hooks';
import { FiWifiOff } from 'react-icons/fi';


const App = ()=>{
  const {isConnected} = useNetworkConnection();

  const [cartData,setCartData] = useState([]);
  const cartDataProvider = useMemo(()=>[cartData,setCartData],[cartData,setCartData]);

  const [colorScheme,setColorSheme] = useState("light");
 

  const [userData,setUserData] = useState({loggedIn:false,data:{}});
  const userDataProvider = useMemo(()=>[userData,setUserData],[userData,setUserData]);

  const [favoritesData,setFavoritesData] = useState({loggedIn:false,data:{}});
  const favoritesDataProvider = useMemo(()=>[favoritesData,setFavoritesData],[favoritesData,setFavoritesData]);

  const [productSelectedData,setProductSelectedData] = useState({loggedIn:false,data:{}});
  const productSelectedDataProvider = useMemo(()=>[productSelectedData,setProductSelectedData],[productSelectedData,setProductSelectedData]);
  useEffect(()=>{
    //localStorage.removeItem("cartData");
    const cartInfo = localStorage.getItem("cartData");
    const hasData = localStorage.getItem("hasData");
    if(hasData == null){
      localStorage.clear();
    }
    
    localStorage.setItem("hasData",new Date(Date.now()).toString());

    if(cartInfo !== null){
      setCartData(JSON.parse(cartInfo));
    }



    const userInfo = window.localStorage.getItem("user");
    if(userInfo !== null){
      setUserData(JSON.parse(userInfo));
    }
    if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches){
      //console.log("Dark");
    }else{
      //console.log("Light");
    }
    window.matchMedia("(prefers-color-scheme:dark)").addEventListener("change",(e)=>{
    const colorScheme = e.matches?"dark":"light";
    console.log(colorScheme);
    setColorSheme(colorScheme);
  })
  },[])

  const CLIENT_ID = "98295596243-eenn3lr0chdtk660atehrt1qsh7s8b8d.apps.googleusercontent.com";

  return (
    <HelmetProvider>
    <div style={{
      backgroundColor:"#f5f5f5f5",
      height:"100vh",
      width:"100%",
      position:"relative"
    }} >
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <CookieBanner/>
      <CookiesProvider defaultSetOptions={{path:"/"}} >
      <CartContext.Provider value={cartDataProvider}>
        <UserContext.Provider value={userDataProvider}>
          <FavoritesContext.Provider value={favoritesDataProvider}>
            <ProductSelectedContext.Provider value={productSelectedDataProvider} >
            <Router>
              <Routes>
                <Route exact path={"/"} element={<HomePage/>} />
                <Route exact path={`/shop`} element={<Shop.ShopPage/>}/>
                <Route exact path={`/shop/:categoryName/:categoryID`} element={<Shop.ShopCategoryProductsPage/>}/>
                <Route exact path={`/company/:companyName/:companyID`} element={<Shop.ShopProductsPage/>}/>
                <Route exact path={`/login`} element={<Auth.LoginPage/>}/>
                <Route exact path={`/register`} element={<Auth.RegisterPage/>}/>
                <Route exact path={`/flash-sales`} element={<FlashSale/>}/>

                <Route exact path={`/about-us`} element={<Others.AboutUs/>}/>
                <Route exact path={`/contact-us`} element={<Others.ContactUs/>}/>
                <Route exact path={`/help`} element={<Others.Help/>}/>
                <Route exact path={`/return-policy`} element={<Others.ReturnPolicy/>}/>

                <Route exact path={`/products`} element={<ProductsPage/>}/>
                <Route exact path={`/products/:productName/:productID`} element={<ProductPage/>}/>
                <Route exact path={`/products/rating/:productID`} element={<Rating.ProductRatings/>}/>
                <Route exact path={`/products/rating/add-rating`} element={<Rating.AddRating/>}/>
                <Route exact path={`/profile`} element={<User.Profile/>}/>
                <Route exact path={`/profile/edit`} element={<User.EditProfile/>}/>
                <Route exact path={`/profile/edit-password`} element={<User.EditPassword/>}/>
                <Route exact path={`/profile/inbox`} element={<User.Inbox/>}/>
                <Route exact path={`/profile/addresses`} element={<User.Addresses/>}/>
                <Route exact path={`/profile/add-address`} element={<User.AddAddress/>}/>
                <Route exact path={`/profile/edit-address/:addressID`} element={<User.EditAddress/>}/>
                <Route exact path={`/profile/orders`} element={<User.Orders/>}/>
                <Route exact path={`/profile/orders/:id`} element={<OrderDetails/>}/>
                <Route exact path={`/profile/settings`} element={<User.Settings/>}/>
                <Route exact path={`/shop/dashboard/:shopID`} element={<ShopDashboard.Dashboard/>}/>
                <Route exact path={`/cart`} element={<CartPage/>}/>
                <Route exact path={`/checkout`} element={<Checkout.CheckoutPage/>}/>
                <Route exact path={`/checkout/:id`} element={<Checkout.CheckoutOrder/>}/>
                <Route exact path={`/favorites`} element={<FavoritesPage/>}/>
                <Route exact path={`/product-ratings/:productID`} element={<Rating.ProductRatings/>}/>
                <Route exact path={`/add-rating`} element={<Rating.AddRating/>}/>
                <Route exact path={`/search`} element={<SearchScreen/>}/>
                <Route exact path={`/location/:latitude/:longitude`} element={<MapPage/>}/>
                <Route exact path={`/location/:latitude/:longitude/:info`} element={<MapPage/>}/>
                <Route exact path={`/deliveries`} element={<User.Deliveries/>}/>
                <Route exact path={`/deliveries/:deliveryID`} element={<MapPage/>}/>
                <Route exact path={`/trial`} element={<TrialPages.TrialPage/>}/>
                <Route exact path={`/map`} element={<MapBoxMap/>}/>
              </Routes>

              
            </Router>
            
            </ProductSelectedContext.Provider>
          </FavoritesContext.Provider>
        </UserContext.Provider>
      </CartContext.Provider>
      </CookiesProvider>
      </GoogleOAuthProvider>

    {/* <div
    style={{
      position:"absolute",
      bottom:5,
      width:"100%",
      borderRadius:20,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      display:isConnected?"none":"flex"
    }}
    >
      <p
      style={{
        borderRadius:20,
        padding:10,
        display:"flex",
        gap:10,
        alignItems:"center",
        justifyContent:"center",
        backgroundColor:"var(--app-danger)",
        alignSelf:"center",
        color:"#fff",
        
      }}
      ><FiWifiOff size={20}/>
        {isConnected?"Connected":"Not connected to internet."}</p>
    </div> */}

    </div>
     
    </HelmetProvider>
  )
}

export default App;