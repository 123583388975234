import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCar } from "react-icons/fa";
import styles from "./index.module.css";
import { APPDATAURLS, BASEURL } from "../../utils/APPADATAURLS";
import {
  HomeCategoriesLoading,
  HorizontalProductsLoading,
  ProductLoading,
} from "../Loading";
import HeaderHero from "../HeaderHero";
const CategoriesBanner = ({ setIvisible }) => {
  const [categories, setCategories] = useState({
    data: [],
    loading: false,
    error: false,
    message: "",
  });

  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchCategories = () => {
    setCategories({
      error: false,
      message: "",
      data: [],
      loading: true,
    });
    fetch(APPDATAURLS.categories, {
      method: "get",
    })
      .then((req) => req.json())
      .then((response) => {
        setCategories({
          data: response.data,
          loading: false,
          error: response.error,
          message: response.message,
        });
      })
      .catch((e) => {
        console.log(e);
        setCategories({
          data: [],
          loading: false,
          error: true,
          message: "An error occurred. Try again later",
        });
      });
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className={styles.holder}>
      <HeaderHero
        heading={"Categories"}
        viewAll={true}
        toUrl={"/shop"}
        subTitle={"shop by category"}
      />
      <div className={styles.category_banner}>
        {categories.error ? (
          <div>{categories.message}</div>
        ) : categories.loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 200,
                    height: 200,
                    background:
                      "linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%",
                    backgroundRepeat: "no-repeat",
                    animation: "shimmer 2.5s infinite",
                    borderRadius:20
                  }}
                ></div>
              );
            })}
          </div>
        ) : categories.data.length < 1 ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Their is nothing here!</p>
          </div>
        ) : (
          categories.data.map((category, index) => {
            return (
              <Link
                onClick={() => {}}
                key={index}
                to={`/shop/${category.categoryName.split(" ").join("-")}/${
                  category.id
                }`}
                className={styles.category_link}
              >
                <img
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setImageLoaded(false)}
                  style={{
                    display: imageLoaded ? "flex" : "flex",
                  }}
                  className={styles.star}
                  src={`${BASEURL}/${category.categoryImage}`}
                />
                <img
                  style={{
                    display: imageLoaded ? "none" : "none",
                  }}
                  className={styles.star}
                  src={"/assets/images/savo_logo.jpg"}
                />
                <p className={styles.p}>{category.categoryName}</p>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default CategoriesBanner;
