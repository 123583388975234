import { Link } from "react-router-dom";
import {
    FaFacebook,
    FaTwitter,
    FaLinkedin,
    FaWhatsapp,
    FaInstagram,
    FaWifi,
    FaPhone,
    FaMailBulk
} from "react-icons/fa";
import "./index.css";
import { useEffect, useState } from "react";
const Footer = ()=>{
    const [email,setEmail] = useState(null);
    const year = new Date().getFullYear();

    useEffect(()=>{
        let user = localStorage.getItem("user");
        if(user){
            user = JSON.parse(user);
            setEmail(user?.data.email);
        }
    },[]);
    return(
        <div className={"footer"}>
            <div className={"links"}>
                <div className={"quick-links"}>
                    <h3>Quick links</h3>
                    <Link className="link" to="/">
                        Marketplace
                    </Link>
                    <Link className="link" to="/">
                        Sell on savowarehouse.com
                    </Link>
                    <Link className="link" to="/help">
                        Terms & Conditions
                    </Link>
                    <Link className="link" to="/return-policy">
                        Return Policy
                    </Link>
                </div>

                <div className={"quick-links"}>
                    <h3>Customer Service</h3>
                    <Link className="link" to="/faqs">
                        Frequently Asked Questions
                    </Link>
                    <Link className="link" to="/help">
                        Help
                    </Link>
                    <Link className="link" to="/contact-us">
                        Contact us
                    </Link>
                    <Link className="link" to="/about-us">
                        About Us
                    </Link>
                </div>

                <div className={"quick-links"}>
                    <h3>Contact us</h3>
                    <Link className="link" to="tel://012324267">
                        <FaPhone size={20} fill={"#ffffff"} />
                        012324267
                    </Link>
                    <Link className="link" to="mailto://info@savowarehouse.com">
                        <FaMailBulk size={20} fill={"#ffffff"} />
                        info@savowarehouse.com
                    </Link>
                    <Link className="link" to="/contact-us">
                        <FaWifi size={20} fill={"#ffffff"} />
                        Contacts
                    </Link>
                </div>

                <div className={"quick-links"}>
                    <h3>Join our newsletter</h3>
                    <p className="link" >
                        Access our best deals and tips.
                    </p>
                    <div className="subscribe" >
                        <input 
                         value={email}
                         onChange={(e)=>setEmail(e.target.value)}
                         placeholder={"Enter email..."} />
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>

            <div className={"divider"}  >
                <hr />
            </div>

            

            <div className={"social"}>
                <p>Copyright @ {year} savowarehouse.com. All rights reserved.</p>
                <div className={"social-media"}>
                    <Link className="footer-link" to={"https://facebook.com"}>
                        <FaFacebook size={20} fill={"#ffffff"} />
                    </Link>
                    <Link className="footer-link" to={"https://twitter.com"}>
                        <FaTwitter size={20} fill={"#ffffff"} />
                    </Link>
                    <Link className="footer-link" to={"https://instagram.com"}>
                        <FaInstagram size={20} fill={"#ffffff"} />
                    </Link>
                    <Link className="footer-link" to={"https://wa.me.com"}>
                        <FaWhatsapp size={20} fill={"#ffffff"} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;