import React, { useEffect, useState } from 'react'

const useNetworkConnection = () => {
  let [isOnline,_] = useState(window.navigator.onLine);

  const [connection,setConnection] = useState({
    state:false,isConnected:false,
  })

  useEffect(()=>{
    
    window.addEventListener("online",(e)=>{
      console.log(e);
      

        setConnection({state:true,isConnected:true});
    });

    window.addEventListener("offline",(e)=>{
      console.log(e);
      
        setConnection({state:false,isConnected:false});
    });
  },[isOnline]);

  return {...connection};

}

export default useNetworkConnection;